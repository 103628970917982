import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { HeaderLogo } from './partials/Logo';

import { logout, getLoginToken } from '../../utils/Login';
import { getCookie } from '../../utils/Cookie';

const propTypes = {
  active: PropTypes.bool,
  navPosition: PropTypes.string,
  hideNav: PropTypes.bool,
  hideSignin: PropTypes.bool,
  bottomOuterDivider: PropTypes.bool,
  bottomDivider: PropTypes.bool
};

const defaultProps = {
  active: false,
  navPosition: '',
  hideNav: false,
  hideSignin: false,
  bottomOuterDivider: false,
  bottomDivider: false
};

class Header extends React.Component {
  state = {
    isActive: false,
    hasLogin: false
  };

  nav = React.createRef();
  hamburger = React.createRef();

  componentDidMount () {
    const isRememberMe = getCookie('rememberMe');
    const token = getCookie('token');
    const localToken = getLoginToken();
    this.props.active && this.openMenu();
    document.addEventListener('keydown', this.keyPress);
    document.addEventListener('click', this.clickOutside);
    if (localToken || (isRememberMe && token)) {
      this.setState({ hasLogin: true });
    } else {
      this.setState({ hasLogin: false });
    }
  }

  componentDidUpdate () {
    const isRememberMe = getCookie('rememberMe');
    const token = getCookie('token');
    const localToken = getLoginToken();
    if ((localToken || (isRememberMe && token)) && !this.state.hasLogin) {
      this.setState({ hasLogin: true });
    } else if (
      !(localToken || (isRememberMe && token)) &&
      this.state.hasLogin
    ) {
      this.setState({ hasLogin: false });
    }
  }

  componentWillUnmount () {
    document.removeEventListener('keydown', this.keyPress);
    document.addEventListener('click', this.clickOutside);
    this.closeMenu();
  }

  openMenu = () => {
    document.body.classList.add('off-nav-is-active');
    this.nav.current.style.maxHeight = this.nav.current.scrollHeight + 'px';
    this.setState({ isActive: true });
  };

  closeMenu = () => {
    document.body.classList.remove('off-nav-is-active');
    this.nav.current && (this.nav.current.style.maxHeight = null);
    this.setState({ isActive: false });
  };

  keyPress = e => {
    this.state.isActive && e.keyCode === 27 && this.closeMenu();
  };

  clickOutside = e => {
    if (!this.nav.current) return;
    if (
      !this.state.isActive ||
      this.nav.current.contains(e.target) ||
      e.target === this.hamburger.current
    )
      return;
    this.closeMenu();
  };

  render () {
    const {
      className,
      active,
      navPosition,
      hideNav,
      hideSignin,
      bottomOuterDivider,
      bottomDivider,
      ...props
    } = this.props;

    const classes = classNames(
      'site-header',
      bottomOuterDivider && 'has-bottom-divider',
      className
    );

    return (
      <header {...props} className={classes}>
        <div className="container">
          <div
            className={classNames(
              'site-header-inner',
              bottomDivider && 'has-bottom-divider'
            )}
          >
            <HeaderLogo />
            {!hideNav && (
              <React.Fragment>
                <button
                  ref={this.hamburger}
                  className="header-nav-toggle"
                  onClick={this.state.isActive ? this.closeMenu : this.openMenu}
                >
                  <span className="screen-reader">Menu</span>
                  <span className="hamburger">
                    <span className="hamburger-inner"></span>
                  </span>
                </button>
                <nav
                  ref={this.nav}
                  className={classNames(
                    'header-nav',
                    this.state.isActive && 'is-active'
                  )}
                >
                  <div className="header-nav-inner">
                    <ul
                      className={classNames(
                        'list-reset text-xxs',
                        navPosition && `header-nav-${navPosition}`
                      )}
                    >
                      <li>
                        <a href="/business">CUSTOMIZE SERVICES</a>
                      </li>
                      <li>
                        <a href="https://doc.zettavisor.com/blogs/blog-list">BLOGS</a>
                      </li>
                      {this.state.hasLogin ? (
                        <li>
                          <a
                            href="https://hub.zettavisor.com"
                            onClick={e => {
                              e.preventDefault();
                              window.open('https://hub.zettavisor.com');
                            }}
                          >
                            Console
                          </a>
                        </li>
                      ) : null}
                    </ul>
                    {!hideSignin && (
                      <ul className="list-reset header-nav-right">
                        <li>
                          {!this.state.hasLogin ? (
                            <Link
                              to="/login/"
                              className="button button-secondary button-wide-mobile button-sm"
                              onClick={this.closeMenu}
                            >
                              Sign in
                            </Link>
                          ) : (
                            <Link
                              className="text-xxs"
                              to="#"
                              onClick={() => {
                                const pathname =
                                  this.props.history.location.pathname;
                                logout();
                                this.closeMenu();
                                if (pathname.includes('myServices')) {
                                  this.props.history.push('/');
                                }
                              }}
                            >
                              Log out
                            </Link>
                          )}
                        </li>
                        {!this.state.hasLogin ? (
                          <li>
                            <Link
                              to="/signup/"
                              className="button button-primary button-wide-mobile button-sm"
                              onClick={this.closeMenu}
                            >
                              Sign up
                            </Link>
                          </li>
                        ) : null}
                      </ul>
                    )}
                  </div>
                </nav>
              </React.Fragment>
            )}
          </div>
        </div>
      </header>
    );
  }
}

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;
