export const Config = {
  12: {
    width: 65,
    unit: 'B',
    divide: 1000000000,
    color: '#d629ff',
  },
  11: {
    width: 60,
    unit: 'B',
    divide: 1000000000,
    color: '#d629ff',
  },
  10: {
    width: 55,
    unit: 'B',
    divide: 1000000000,
    color: '#d629ff',
  },
  9: {
    width: 50,
    unit: 'M',
    divide: 1000000,
    color: '#fe567f',
  },
  8: {
    width: 45,
    unit: 'M',
    divide: 1000000,
    color: '#fe567f',
  },
  7: {
    width: 40,
    unit: 'M',
    divide: 1000000,
    color: '#fe567f',
  },
  6: {
    width: 35,
    unit: 'K',
    divide: 1000,
    color: '#1fcffd',
  },
  5: {
    width: 30,
    unit: 'K',
    divide: 1000,
    color: '#1fcffd',
  },
  4: {
    width: 25,
    unit: 'K',
    divide: 1000,
    color: '#1fcffd',
  },
};
