import { getLoginToken } from './Login'
const host = '/zettavisor/visitor'
const hostUser = '/zettavisor/user'

const Fetch = (options, isUser) => {
  const addr = isUser ? hostUser : host
  return fetch(addr, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      Authorization: `Bearer ${getLoginToken() || ''}`
    },
    body: JSON.stringify({
      operationName: options.operationName,
      query: options.query,
      variables: options.variables
    })
  })
    .then(response => {
      if ((response?.status ?? '') !== 200) {
        return response
      }
      return response.json()
    })
    .then(data => {
      return data
    })
    .catch(error => {
      return error
    })
}

export const getLogin = variables => {
  return Fetch({
    operationName: 'getLogin',
    query:
      'mutation getLogin($email: String, $password: String){login(email: $email, password: $password)}',
    variables: {
      ...variables
    }
  })
}

export const signup = variables => {
  return Fetch({
    operationName: 'signup',
    query:
      'mutation signup($email: String, $password: String, $username: String, $code: String, $nickname: String) {signUp(email: $email, password: $password, username: $username, code: $code, nickname: $nickname)}',
    variables: {
      ...variables
    }
  })
}

export const sendEmailCode = variables => {
  return Fetch({
    operationName: 'sendEmailCode',
    query:
      'mutation sendEmailCode($token: String, $lang: String, $email: String!){sendEmailCode(token: $token, lang: $lang, email: $email)}',
    variables: {
      ...variables
    }
  })
}

export const sendEmailUrl = variables => {
  return Fetch({
    operationName: 'sendEmailUrl',
    query:
      'mutation sendEmailUrl($email: String!, $token: String, $lang: String){sendEmailUrl(email: $email, token: $token, lang: $lang)}',
    variables: {
      ...variables
    }
  })
}

export const getBitcoin = () => {
  return fetch(
    '/api/datasources/proxy/1/query?db=zettavisor&q=zettavisor_cryptocurrency_data_usd_bitcoin&epoch=ms',
    {
      method: 'GET',
      cache: 'no-cache'
    }
  )
    .then(response => {
      return response.json()
    })
    .then(data => {
      return data
    })
    .catch(error => {
      return error
    })
}

export const getBitCash = () => {
  return fetch(
    '/api/datasources/proxy/1/query?db=zettavisor&q=zettavisor_cryptocurrency_data_usd_bitcoin-cash&epoch=ms',
    {
      method: 'GET',
      cache: 'no-cache'
    }
  )
    .then(response => {
      return response.json()
    })
    .then(data => {
      return data
    })
    .catch(error => {
      return error
    })
}

export const getEthereum = () => {
  return fetch(
    '/api/datasources/proxy/1/query?db=zettavisor&q=zettavisor_cryptocurrency_data_usd_ethereum&epoch=ms',
    {
      method: 'GET',
      cache: 'no-cache'
    }
  )
    .then(response => {
      return response.json()
    })
    .then(data => {
      return data
    })
    .catch(error => {
      return error
    })
}

export const getXRP = () => {
  return fetch(
    '/api/datasources/proxy/1/query?db=zettavisor&q=zettavisor_cryptocurrency_data_usd_binance-peg-xrp&epoch=ms',
    {
      method: 'GET',
      cache: 'no-cache'
    }
  )
    .then(response => {
      return response.json()
    })
    .then(data => {
      return data
    })
    .catch(error => {
      return error
    })
}

export const getAllEncrypt = () => {
  return fetch(
    'https://influxdb-proxy-c1-public.zettavisor.com/query?q=5a75a6bc-6ca4-11ec-8c86-eb3e8c9f2f74&db=zettavisor',
    {
      method: 'GET',
      cache: 'no-cache'
    }
  )
    .then(response => {
      return response.json()
    })
    .then(data => {
      return data
    })
    .catch(error => {
      return error
    })
}

export const getNonceMessage = () => {
  return Fetch({
    operationName: 'getMessage',
    query: `query getMessage {nonceMessage}`
  })
}

export const loginByPhantom = variables => {
  return Fetch({
    operationName: 'loginByWeb3',
    query:
      'mutation loginByWeb3($msg: String, $sign: String, $pubKey: String, $wallet: walletEnumType){loginByWeb3(msg: $msg, sign: $sign, pubKey: $pubKey, wallet: $wallet)}',
    variables: {
      ...variables
    }
  })
}
