import React from 'react';
import './index.scss';
import Blog_001 from './001';
import Blog_002 from './002';
import Blog_003 from './003';
import Blog_004 from './004';
import Blog_005 from './005';

class Blog extends React.Component {
  constructor (props) {
    super(props);

    const { id } = props.match.params;
    const winWidth = window.innerWidth;

    this.state = {
      invertMobile: winWidth <= 640 ? true : false,
      id: id
    };
  }

  render () {
    return (
      <React.Fragment>
        <section className="section illustration-section-01">
          <div className="container blog-container">
            {/* eslint-disable-next-line */}
            {this.state.id === '001' && <Blog_001></Blog_001>}
            {/* eslint-disable-next-line */}
            {this.state.id === '002' && <Blog_002></Blog_002>}
            {/* eslint-disable-next-line */}
            {this.state.id === '003' && <Blog_003></Blog_003>}
            {/* eslint-disable-next-line */}
            {this.state.id === '004' && <Blog_004></Blog_004>}
            {/* eslint-disable-next-line */}
            {this.state.id === '005' && <Blog_005></Blog_005>}
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Blog;
