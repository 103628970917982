import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { SectionProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Input from '../elements/Input';
import Button from '../elements/Button';
import { sendEmailUrl } from '../../utils/Apis';

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

class RecoverPassword extends React.Component {
  state = {
    forms: { email: '' },
    loading: false,
  };

  render() {
    const { className, topOuterDivider, bottomOuterDivider, topDivider, bottomDivider, hasBgColor, invertColor, ...props } = this.props;

    const outerClasses = classNames(
      'signin section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames('signin-inner section-inner', topDivider && 'has-top-divider', bottomDivider && 'has-bottom-divider');

    const sectionHeader = {
      title: 'Reset Password',
    };

    const handleChange = (e, type) => {
      let value = e.target.value;
      if (type === 'checkbox') {
        value = e.target.checked;
      }
      this.setState({ forms: { ...this.state.forms, [e.target.name]: value } });
    };

    const handleResetPassword = () => {
      const forms = this.state.forms;
      const { email } = forms;
      this.setState({ loading: true });
      sendEmailUrl({ email })
        .then((data) => {
          this.setState({ loading: false });
          if (data?.data?.sendEmailUrl) {
            toast.success('A reset email has been sent, please reset your password!');
            this.props.history.push('/login');
          } else {
            toast.error("The account doesn't exist, please register your account!");
          }
        })
        .catch(() => {
          this.setState({ loading: false });
        });
    };

    return (
      <section {...props} className={outerClasses}>
        <div className='container'>
          <div className={innerClasses}>
            <SectionHeader tag='h1' data={sectionHeader} className='center-content' />

            <div className='tiles-wrap'>
              <div className='tiles-item'>
                <div className='tiles-item-inner'>
                  <form
                    onSubmit={(e) => {
                      handleResetPassword();
                      e.preventDefault();
                    }}
                  >
                    <fieldset>
                      <div className='mb-12'>
                        <Input type='email' name='email' label='Email' placeholder='Email' onChange={handleChange} required />
                      </div>
                      <div className='mt-24 mb-32'>
                        <Button color='primary' wide>
                          {this.state.loading ? 'Loading ...' : 'Send Email'}
                        </Button>
                      </div>
                    </fieldset>
                  </form>

                  <div className='signin-footer mb-32'>
                    <Link to='/login' className='func-link text-xs'>
                      Login
                    </Link>
                  </div>

                  <div className='signin-bottom has-top-divider'>
                    <div className='pt-32 text-xs center-content text-color-low'>
                      Don't you have an account?{' '}
                      <Link to='/signup' className='func-link'>
                        Sign up
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

RecoverPassword.propTypes = propTypes;
RecoverPassword.defaultProps = defaultProps;

export default RecoverPassword;
