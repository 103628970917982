import React from 'react';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';


const LayoutDefault = ({ children, history }) => {
  return (
    <React.Fragment>
      <Header navPosition='right' className='reveal-from-bottom' history={history} />
      <main className='site-content'>{children}</main>
      <Footer topDivider className='illustration-section-09' />
    </React.Fragment>
  );
};

export default LayoutDefault;
