import React from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Button from '../elements/Button';
import Image from '../elements/Image';
import headerImg from '../../assets/images/home-header-02.jpg';

import { getCookie } from '../../utils/Cookie';
import { getLoginToken } from '../../utils/Login';

const propTypes = {
  ...SectionProps.types
};

const defaultProps = {
  ...SectionProps.defaults
};

class HeroFull extends React.Component {
  render () {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      invertMobile,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'hero center-content',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'hero-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    return (
      <section {...props} className={outerClasses}>
        <div
          style={{
            width: '100%',
            height: '100vh',
            position: 'relative',
            overflow: 'hidden'
          }}
        >
          <img
            src={headerImg}
            alt="header"
            style={{
              display: 'block',
              minWidth: '100%',
              height: '100vh',
              objectFit: 'cover'
            }}
          />
          <div
            className={`reveal-from-bottom`}
            style={StartButton}
            data-reveal-delay="600"
          >
            <h2>
              Better Decision Empowered
              <br /> by Quantitative Analysis.
            </h2>
            <Button
              color="primary"
              style={{
                fontSize: invertMobile ? '18px' : '32px',
                padding: invertMobile ? '10px 20px' : '20px 50px',
                height: 'auto'
              }}
              onClick={event => {
                const isRememberMe = getCookie('rememberMe');
                const token = getCookie('token');
                const localToken = getLoginToken();
                if (localToken || (isRememberMe && token)) {
                  window.location.href = 'https://hub.zettavisor.com';
                } else {
                  window.location.href = '/login';
                }
                event.preventDefault();
              }}
            >
              Get started
            </Button>
          </div>
        </div>

        <div className="container-sm">
          <div className={innerClasses}>
            <div className="hero-content">
              {/* <h1
                className='mt-0 mb-16 reveal-from-bottom'
                data-reveal-delay='200'
              >
                ZettaVisor
              </h1> */}
              <div className="container-sm">
                <p
                  className="m-0 mb-16 ta-l reveal-from-bottom"
                  data-reveal-delay="400"
                >
                  Zettavisor is a comprehensive SaaS platform for data
                  aggregation, calculation, compilation, and output.
                </p>
                {/* Furthermore, we provide cloud infrastructure that is dependent on the data platform. */}
                <p
                  className="m-0 mb-16 ta-l reveal-from-bottom"
                  data-reveal-delay="400"
                >
                  At the product level, we provide a range of products for small
                  and medium-sized businesses, including data collectors, data
                  mining, and data reporting. We also offer tools for data
                  visualization.
                </p>
                <p
                  className="m-0 mb-16 ta-l reveal-from-bottom"
                  data-reveal-delay="400"
                >
                  Recently, we have been closely following the ChatGPT trend and
                  exploring ways to integrate our real-time data analysis and
                  data mining capabilities to train AI models tailored for
                  specific purposes, providing added value to our customers.{' '}
                </p>
                <p
                  className="m-0 mb-16 ta-l reveal-from-bottom"
                  data-reveal-delay="400"
                >
                  We are currently engaged in research and development efforts
                  focused on AI-enabled products such as AI Sales and AI
                  Assistants. These innovative solutions aim to enhance sales
                  processes and provide personalized assistance to customers. By
                  leveraging our expertise in data analysis and data mining.
                </p>
              </div>
            </div>
            <div
              className="hero-figure illustration-element-01 reveal-from-bottom"
              data-reveal-value="20px"
              data-reveal-delay="800"
            >
              <Image
                className="has-shadow"
                src={require('./../../assets/images/hero-image-new.png')}
                alt="Hero"
                width={896}
                height={504}
              />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const StartButton = {
  position: 'absolute',
  bottom: '35%',
  left: '6%',
  textAlign: 'left',
  color: '#fff'
};

HeroFull.propTypes = propTypes;
HeroFull.defaultProps = defaultProps;

export default HeroFull;
