import React from 'react';
import LineChart from '../LineChart';

class CryptoCurrency extends React.Component {
  render() {
    return (
      <div>
        <LineChart
          data={[
            {
              name: this.props.name + ' ($)',
              color: this.props.color || 'steelblue',
              items: this.props.data.map((d) => ({ date: new Date(d[0]), value: d[1] })),
            },
          ]}
          dimensions={{
            width: 350,
            height: 140,
            margin: {
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            },
            xTicks: 0,
            yTicks: 0,
            spacing: this.props.spacing || 40
          }}
        />
      </div>
    );
  }
}

export default CryptoCurrency;
