import React, { useRef, useEffect } from 'react';
import * as d3 from 'd3';

// Copyright 2021 Observable, Inc.
// Released under the ISC license.
// https://observablehq.com/@d3/line-chart
let pointer = 0;
let SI = {};
export default function LineChart({ data = [], dimensions = {} }) {
  const svgRef = useRef(null);
  const { width, height, margin = {}, yTicks = 5, xTicks = 5, spacing = 30 } = dimensions;
  const svgHeight = height + margin.top + margin.bottom;

  useEffect(() => {
    const xScale = d3
      .scaleTime()
      .domain(d3.extent(data[0].items, (d) => d.date))
      .range([0, width]);
    const yScale = d3
      .scaleLinear()
      .domain([d3.min(data[0].items, (d) => d.value) - spacing, d3.max(data[0].items, (d) => d.value) + spacing])
      .range([height, 0]);

    // Create root container where we will append all other chart elements
    const svgEl = d3.select(svgRef.current);
    svgEl.selectAll('*').remove(); // Clear svg content before adding new elements
    const svg = svgEl.append('g').attr('transform', `translate(${margin.left},${margin.top})`);

    // Add X grid lines with labels
    const xAxis = d3
      .axisBottom(xScale)
      .ticks(xTicks === 0 ? 0 : 5)
      .tickSize(0);
    const xAxisGroup = svg
      .append('g')
      .attr('transform', `translate(0, ${height - margin.bottom})`)
      .call(xAxis);
    xAxisGroup.select('.domain').remove();
    xAxisGroup.selectAll('line').attr('stroke', 'rgba(255, 255, 255, 0.2)');
    xAxisGroup.selectAll('text').attr('opacity', 0.5).attr('color', 'white').attr('font-size', '0.75rem');

    // Add Y grid lines with labels
    const yAxis = d3
      .axisLeft(yScale)
      .ticks(yTicks === 0 ? 0 : 5)
      .tickSize(0);
    const yAxisGroup = svg.append('g').call(yAxis);
    yAxisGroup.select('.domain').remove();
    yAxisGroup.selectAll('line').attr('stroke', 'rgba(255, 255, 255, 0.2)');
    yAxisGroup.selectAll('text').attr('opacity', 0.5).attr('color', 'white').attr('font-size', '0.75rem');

    // Draw the lines
    const line = d3
      .line()
      .curve(d3.curveLinear)
      .x((d) => {
        const l = xScale(d.date);
        d.l = Math.round(l);
        return l;
      })
      .y((d) => {
        const t = yScale(d.value);
        d.t = Math.round(t);
        return t;
      });

    svg
      .selectAll('.line')
      .data(data)
      .enter()
      .append('path')
      .attr('fill', 'none')
      .attr('stroke', (d) => d.color)
      .attr('stroke-width', 1)
      .attr('d', (d) => {
        return line(d.items);
      });

    // Add circle

    const fieldCircle = svg
      .append('circle')
      .data(data)
      .attr('r', 1)
      .attr('fill', '#11c7ed')
      .attr('stroke', data[0].color)
      .style('transform', (d) => {
        const l = d.items[0].l;
        const t = d.items[0].t;
        return `translate(${l}px, ${t}px)`;
      });

    const name = data[0].name
    SI[name] && clearInterval(SI[name]);
    SI[name] = setInterval(() => {
      if (pointer < data[0].items.length) {
        const l = data[0].items[pointer].l;
        const t = data[0].items[pointer].t;
        fieldCircle.attr('style', `transform: translate(${l}px, ${t}px)`);
        pointer++;
      } else {
        pointer = 0;
      }
    }, 30);
    // eslint-disable-next-line
  }, [data]);

  return <svg ref={svgRef} width='100%' height={svgHeight}></svg>;
}
