import React from 'react';
import LoginForm from '../components/sections/LoginForm';

class Login extends React.Component {
  render() {
    const { history } = this.props;
    const props = { history };

    return <LoginForm className='illustration-section-01' {...props} />;
  }
}

export default Login;
