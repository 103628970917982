import React from 'react';
import { getBitcoin, getEthereum, getBitCash, getXRP, getAllEncrypt } from '../../utils/Apis';
import { numberWithCommas } from '../../utils/Methods';
import Bubbles from './Bubbles';
import CryptoCurrency from './CryptoCurrency';
import RingComponent from './PieChart/ring';
import Highchart from './Highchart';

class Dashboard extends React.Component {
  state = {
    bitcoinData: [],
    bitcoinMoney: 0,
    ethereumData: [],
    ethereumMoney: 0,
    bitCashData: [],
    bitCashMoney: 0,
    xrpData: [],
    xrpMoney: 0,
    topUsd: [],
    topCap: [],
    categories: [],
    volumeData: [],
    rangeData: [],
  };

  componentDidMount() {
    getBitcoin().then((data) => {
      if (data?.results[0]?.series[0]?.values) {
        const values = data.results[0].series[0].values;
        let money = 0;
        const arr = values.filter((d) => {
          money = d[1];
          return typeof d[1] === 'number';
        });
        this.setState({ bitcoinData: [...arr], bitcoinMoney: money });
      }
    });

    getBitCash().then((data) => {
      if (data?.results[0]?.series[0]?.values) {
        const values = data.results[0].series[0].values;
        let money = 0;
        const arr = values.filter((d) => {
          money = d[1];
          return typeof d[1] === 'number';
        });
        this.setState({ bitCashData: [...arr], bitCashMoney: money });
      }
    });

    getEthereum().then((data) => {
      if (data?.results[0]?.series[0]?.values) {
        const values = data.results[0].series[0].values;
        let money = 0;
        const arr = values.filter((d) => {
          money = d[1];
          return typeof d[1] === 'number';
        });
        this.setState({ ethereumData: [...arr], ethereumMoney: money });
      }
    });

    getXRP().then((data) => {
      if (data?.results[0]?.series[0]?.values) {
        const values = data.results[0].series[0].values;
        let money = 0;
        const arr = values.filter((d) => {
          money = d[1];
          return typeof d[1] === 'number';
        });
        this.setState({ xrpData: [...arr], xrpMoney: money });
      }
    });

    getAllEncrypt().then((data) => {
      const lists = data?.results[0]?.series;

      // ["time", "usd", "cap", "vol", "change"]
      lists.sort(function (a, b) {
        return b.values[0][2] - a.values[0][2];
      });
      const topCap = lists.slice(0, 7);
      const tmpCap = topCap.map((item) => {
        return { name: item.tags.id.toUpperCase(), value: item.values[0][2] };
      });
      this.setState({ topCap: [...tmpCap] });

      lists.sort(function (a, b) {
        return b.values[0][1] - a.values[0][1];
      });
      const topUsd = lists.slice(0, 10);
      const tmpUsd = topUsd.map((item) => {
        return { name: item.tags.id.toUpperCase(), value: item.values[0][1] };
      });
      this.setState({ topUsd: [...tmpUsd] });

      lists.sort(function (a, b) {
        return b.values[0][3] - a.values[0][3];
      });
      const topVol = lists.slice(0, 12);
      let categories = [];
      let rangeData = [];
      let volumeData = [];
      topVol.forEach((item) => {
        categories.push(item.tags.id);
        volumeData.push(item.values[0][3] / 1000000000);
        rangeData.push(item.values[0][4]);
      });
      this.setState({ categories: [...categories], rangeData: [...rangeData], volumeData: [...volumeData] });
    });
  }

  componentWillUnmount() {
    this.setState({ bitcoinData: [] });
  }

  render() {
    return (
      <div className='dashboard'>
        <h1>
          <span style={{ display: 'block' }} className='animate__animated animate__backInDown'>
            Crypto Currency Data
          </span>
        </h1>
        <div className='content'>
          <div className='left'>
            <div className='small-sec'>
              <div className='header'>
                <div className='title animate__animated animate__swing'>Bitcoin</div>
                <div className='price'>
                  <strong>$ {numberWithCommas(this.state.bitcoinMoney)}</strong>
                </div>
              </div>
              <div className='detail'>{this.state.bitcoinData.length ? <CryptoCurrency name='bitcoin' data={this.state.bitcoinData} spacing={100} /> : null}</div>
            </div>

            <div className='small-sec'>
              <div className='header'>
                <div className='title animate__animated animate__swing'>Bit Cash</div>
                <div className='price'>
                  <strong>$ {numberWithCommas(this.state.bitCashMoney)}</strong>
                </div>
              </div>
              <div className='detail'>{this.state.bitCashData.length ? <CryptoCurrency color='#49f7ce' name='bitCash' data={this.state.bitCashData} spacing={5} /> : null}</div>
            </div>

            <div className='small-sec'>
              <div className='header'>
                <div className='title animate__animated animate__swing'>Ethereum</div>
                <div className='price'>
                  <strong>$ {numberWithCommas(this.state.ethereumMoney)}</strong>
                </div>
              </div>
              <div className='detail'>{this.state.ethereumData.length ? <CryptoCurrency color='#faf93e' name='ethereum' data={this.state.ethereumData} spacing={20} /> : null}</div>
            </div>

            <div className='small-sec'>
              <div className='header'>
                <div className='title animate__animated animate__swing'>XRP</div>
                <div className='price'>
                  <strong>$ {numberWithCommas(this.state.xrpMoney)}</strong>
                </div>
              </div>
              <div className='detail'>{this.state.xrpData.length ? <CryptoCurrency color='#d629ff' name='xrp' data={this.state.xrpData} spacing={0.001} /> : null}</div>
            </div>
          </div>
          <div className='center'>
            <Bubbles />

            <div className='sec-bottom'>
              {this.state.categories.length ? <Highchart categories={this.state.categories} volumeData={this.state.volumeData} rangeData={this.state.rangeData} /> : null}
            </div>
          </div>
          <div className='left'>
            <div className='small-sec'>
              <div className='header'>
                <div className='title animate__animated animate__bounceInDown' style={{ paddingLeft: '4px' }}>
                  Market Capital
                </div>
              </div>
              <div className='detail'>
                <br />
                {this.state.topCap && this.state.topCap.length ? <RingComponent data={this.state.topCap} dimensions={{ spacing: 8, margin: 20, width: 320 }} /> : null}
                <br />
              </div>
            </div>

            <div className='small-sec'>
              <div className='header'>
                <div className='title animate__animated animate__bounceInRight' style={{ paddingLeft: '4px' }}>
                  TOP 10 USD
                </div>
              </div>
              <div className='detail'>
                <ul className='list-text'>
                  {this.state.topUsd.map((item, index) => (
                    <li key={index}>
                      <p>
                        {item.name}: $ {numberWithCommas(item.value)}
                      </p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Dashboard;
