import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { SectionProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Input from '../elements/Input';
import Button from '../elements/Button';
import Checkbox from '../elements/Checkbox';

import { signup, sendEmailCode } from '../../utils/Apis';

const propTypes = {
  ...SectionProps.types
};

const defaultProps = {
  ...SectionProps.defaults
};
let SI = null;
class SignupForm extends React.Component {
  state = {
    forms: {
      email: '',
      password: '',
      nickname: '',
      confirmPassword: '',
      code: '',
      isChecked: false
    },
    countDownTime: 0,
    loading: false,
    registerLoading: false
  };

  render () {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'signin section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'signin-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const sectionHeader = {
      title: 'Better Decision Empowered by Zettavisor Analysis.'
    };

    const handleChange = (e, type) => {
      let value = e.target.value;
      if (type === 'checkbox') {
        value = e.target.checked;
      }
      this.setState({ forms: { ...this.state.forms, [e.target.name]: value } });
    };

    const handleCountDownTime = time => {
      this.setState({ countDownTime: time });
      SI && clearInterval(SI);
      SI = setInterval(() => {
        const curT = this.state.countDownTime;
        if (curT <= 0) {
          clearInterval(SI);
          SI = null;
        }
        this.setState({ countDownTime: curT - 1 > 0 ? curT - 1 : 0 });
      }, 1000);
    };

    const handleVerifyCode = () => {
      if (this.state.loading || this.state.countDownTime) return;
      this.setState({ loading: true });
      const email = this.state.forms.email;
      if (!email) {
        toast.error('Please Input The Email');
        return;
      }
      sendEmailCode({ email: email })
        .then(data => {
          this.setState({ loading: false });
          if (data?.data?.sendEmailCode) {
            handleCountDownTime(10);
          } else {
            const errors = data?.errors ?? [];
            toast.error(errors[0].message);
          }
        })
        .catch(() => {
          this.setState({ loading: false });
          handleCountDownTime(0);
        });
    };

    const handleRegister = () => {
      if (this.state.registerLoading) return;
      const forms = this.state.forms;

      const confirmPassword = document.querySelector('#confirmPassword');
      if (forms.password !== forms.confirmPassword) {
        confirmPassword.setCustomValidity(
          'Please keeping twice password are same'
        );
        return;
      } else {
        confirmPassword.setCustomValidity('');
      }
      confirmPassword.reportValidity();

      if (!forms.isChecked) {
        toast.error('You must agree to the Privacy Policy');
        return;
      }

      const { code, nickname, email, password } = forms;
      this.setState({ registerLoading: true });
      signup({ code, nickname, email, password })
        .then(data => {
          this.setState({ registerLoading: false });
          if (data?.data?.signUp) {
            toast.success('Register Successful, Please sign in');
            this.props.history.push('/login');
          } else {
            const errors = data?.errors ?? [];
            toast.error(errors[0].message);
          }
        })
        .catch(() => {
          this.setState({ registerLoading: false });
        });
    };

    return (
      <section {...props} className={outerClasses}>
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader
              tag="h1"
              data={sectionHeader}
              className="center-content"
            />
            <div className="tiles-wrap">
              <div className="tiles-item">
                <div className="tiles-item-inner">
                  <form
                    onSubmit={e => {
                      handleRegister();
                      e.preventDefault();
                    }}
                  >
                    <fieldset>
                      <div className="mb-12">
                        <Input
                          label="* Nickname"
                          name="nickname"
                          placeholder="Nickname"
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="mb-12">
                        <Input
                          type="email"
                          label="* Email"
                          name="email"
                          placeholder="Email"
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="mb-12">
                        <Input
                          type="password"
                          label="* Password"
                          name="password"
                          placeholder="Password"
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="mb-12">
                        <Input
                          id="confirmPassword"
                          type="password"
                          label="* Confirm Password"
                          name="confirmPassword"
                          placeholder="Confirm Password"
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="mb-12">
                        <div className="verify-code">
                          <Input
                            label="Code"
                            name="code"
                            placeholder="Code"
                            labelHidden
                            onChange={handleChange}
                            required
                          />
                          <Button type="button" onClick={handleVerifyCode}>
                            {this.state.loading
                              ? 'Loading...'
                              : this.state.countDownTime
                              ? this.state.countDownTime + 's'
                              : 'Get Verification Code'}
                          </Button>
                        </div>
                      </div>
                      <div className="mb-12">
                        <Checkbox
                          name="isChecked"
                          onChange={e => handleChange(e, 'checkbox')}
                        />
                        I agree to all the{' '}
                        <Link target="_blank" to="/privacyPolicy">
                          Privacy Policy
                        </Link>
                      </div>
                      <div className="mt-24 mb-32">
                        <Button color="primary" wide>
                          {this.state.registerLoading
                            ? 'Loading...'
                            : 'Sign up'}
                        </Button>
                      </div>
                    </fieldset>
                  </form>
                  <div className="signin-bottom has-top-divider">
                    <div className="pt-32 text-xs center-content text-color-low">
                      Already have an account?{' '}
                      <Link to="/login" className="func-link">
                        Login
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

SignupForm.propTypes = propTypes;
SignupForm.defaultProps = defaultProps;

export default SignupForm;
