import React from 'react'
import classNames from 'classnames'
import { SectionSplitProps } from '../../utils/SectionProps'
import SectionHeader from './partials/SectionHeader'
import Image from '../elements/Image'
import SplitSVG from './../../assets/images/features-split-image.svg'
import ArrowProcess from './../../assets/images/arrow-process.svg'

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

class FeaturesSplit extends React.Component {
  render () {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      invertMobile,
      invertDesktop,
      alignTop,
      imageFill,
      ...props
    } = this.props

    const outerClasses = classNames(
      'features-split section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    )

    const innerClasses = classNames(
      'features-split-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    )

    const splitClasses = classNames(
      'split-wrap',
      invertMobile && 'invert-mobile',
      invertDesktop && 'invert-desktop',
      alignTop && 'align-top'
    )

    const sectionHeader = {
      title: 'Analysis made easy',
      paragraph:
        'Many companies struggle to build adaptable organizations and face resistance as well as lost productivity in the face of ongoing change.'
    }

    return (
      <section {...props} className={outerClasses}>
        <div className='container'>
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className='center-content' />
            <div className={splitClasses}>
              <div className='split-item'>
                <div
                  className='split-item-content center-content-mobile reveal-from-left'
                  data-reveal-container='.split-item'
                >
                  <h3 className='mt-0 mb-12'>Data collection</h3>
                  <p className='m-0'>
                    Continuous improvement planning and methodology for data
                    collection.
                  </p>
                </div>
                <div
                  className={classNames(
                    'split-item-image center-content-mobile reveal-from-right',
                    imageFill && 'split-item-image-fill'
                  )}
                  data-reveal-container='.split-item'
                >
                  <Image
                    src={SplitSVG}
                    alt='Features split 01'
                    width={528}
                    height={396}
                  />
                  <div style={imgStyle}>
                    <Image
                      src={require('./../../assets/images/features-split-top-01-new.png')}
                      alt='Features split top 01'
                      width={622}
                      height={510}
                    />
                  </div>
                </div>

                <div
                  className={classNames('split-arrow reveal-from-top')}
                  data-reveal-container='.split-item'
                >
                  <Image
                    src={ArrowProcess}
                    alt='arrow'
                    width={340}
                    height={407}
                  />
                </div>
              </div>

              <div className={classNames('split-item')} style={spaceItem}>
                <div
                  className='split-item-content center-content-mobile reveal-from-right'
                  data-reveal-container='.split-item'
                >
                  <h3 className='mt-0 mb-12'>Data processing</h3>
                  <p className='m-0'>
                    Platform for data mining, aggregation, and analysis.
                  </p>
                </div>
                <div
                  className={classNames(
                    'split-item-image center-content-mobile reveal-from-left',
                    imageFill && 'split-item-image-fill'
                  )}
                  data-reveal-container='.split-item'
                >
                  <Image
                    src={SplitSVG}
                    alt='Features split 02'
                    width={528}
                    height={396}
                  />
                  <div style={imgStyle}>
                    <Image
                      src={require('./../../assets/images/features-split-top-02-new.png')}
                      alt='Features split top 02'
                      width={622}
                      height={510}
                    />
                  </div>
                </div>

                <div
                  className={classNames(
                    'split-arrow split-arrow-transform-y reveal-from-top'
                  )}
                  data-reveal-container='.split-item'
                >
                  <Image
                    src={ArrowProcess}
                    alt='arrow'
                    width={340}
                    height={407}
                  />
                </div>
              </div>

              <div className='split-item'>
                <div
                  className='split-item-content center-content-mobile reveal-from-left'
                  data-reveal-container='.split-item'
                >
                  <h3 className='mt-0 mb-12'>Data report</h3>
                  <p className='m-0'>
                    Presenting insights/data in charts or graphs using an
                    interactive video to visualize significant amounts of
                    complex data is more accessible than relying on spreadsheets
                    or reports.
                  </p>
                </div>
                <div
                  className={classNames(
                    'split-item-image center-content-mobile reveal-from-right',
                    imageFill && 'split-item-image-fill'
                  )}
                  data-reveal-container='.split-item'
                >
                  <Image
                    src={SplitSVG}
                    alt='Features split 03'
                    width={528}
                    height={396}
                  />
                  <div style={imgStyle}>
                    <Image
                      src={require('./../../assets/images/features-split-top-03-new.png')}
                      alt='Features split top 03'
                      width={622}
                      height={510}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

const imgStyle = {
  position: 'absolute',
  width: '117.8%',
  maxWidth: '117.8%',
  top: '-9.9%',
  left: '-5.87%'
}

const spaceItem = {
  margin: '30px 0 20px'
}

FeaturesSplit.propTypes = propTypes
FeaturesSplit.defaultProps = defaultProps

export default FeaturesSplit
