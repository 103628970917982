import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { SectionProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Input from '../elements/Input';
import Button from '../elements/Button';
import Checkbox from '../elements/Checkbox';
import { getLogin } from '../../utils/Apis';
import { setCookie } from '../../utils/Cookie';
import { setLoginToken } from '../../utils/Login';
import { Domain, RememberMeExpires } from '../../utils/Config';
import LoginPhantom from './LoginPhantom';
import LoginMetaMask from './LoginMetaMask';

const propTypes = {
  ...SectionProps.types
};

const defaultProps = {
  ...SectionProps.defaults
};

class LoginForm extends React.Component {
  state = {
    forms: { email: '', password: '', isChecked: false }
  };

  render () {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      history
    } = this.props;
    const NProps = { history };

    const outerClasses = classNames(
      'signin section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'signin-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const sectionHeader = {
      title: 'Better Decision Empowered by Zettavisor Analysis.'
    };

    const handleChange = (e, type) => {
      let value = e.target.value;
      if (type === 'checkbox') {
        value = e.target.checked;
      }
      this.setState({ forms: { ...this.state.forms, [e.target.name]: value } });
    };

    const handleLogin = () => {
      const forms = this.state.forms;
      const { email, password } = forms;
      getLogin({ email, password })
        .then(data => {
          if (data?.data?.login) {
            toast.success('Login Successfully!');
            setLoginToken(data.data.login);
            setCookie(
              'rememberMe',
              String(forms.isChecked),
              forms.isChecked ? RememberMeExpires : -1,
              Domain
            );
            setCookie(
              'token',
              data.data.login,
              forms.isChecked ? RememberMeExpires : 1,
              Domain
            );
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const fromURL = urlParams.get('from');
            if (fromURL) {
              this.props.history.push(fromURL);
            } else {
              this.props.history.push('/');
            }
          } else {
            const errors = data?.errors ?? [];
            toast.error(errors[0].message);
          }
        })
        .catch(() => {});
    };

    return (
      <section className={outerClasses}>
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader
              tag="h1"
              data={sectionHeader}
              className="center-content"
            />
            <div className="tiles-wrap">
              <div className="tiles-item">
                <div className="tiles-item-inner">
                  <form
                    onSubmit={e => {
                      handleLogin();
                      e.preventDefault();
                    }}
                  >
                    <fieldset>
                      <div className="mb-32">
                        <p className="mb-12">
                          Please Choosing Your Wallet Login
                        </p>
                        <div className="button-group buttons-inline">
                          <LoginPhantom {...NProps} />
                          <LoginMetaMask {...NProps} />
                        </div>
                      </div>
                      <div className="mb-12">
                        <Input
                          type="email"
                          name="email"
                          label="Email"
                          placeholder="Email"
                          onChange={handleChange}
                          labelHidden
                          required
                          style={{ color: '#F8FAFC' }}
                        />
                      </div>
                      <div className="mb-12">
                        <Input
                          type="password"
                          name="password"
                          label="Password"
                          placeholder="Password"
                          onChange={handleChange}
                          labelHidden
                          required
                          style={{ color: '#F8FAFC' }}
                        />
                      </div>
                      <div className="mt-24 mb-32">
                        <Button color="primary" wide>
                          Sign in
                        </Button>
                      </div>
                      <div className="signin-footer mb-32">
                        <Checkbox
                          name="isChecked"
                          checked={this.state.forms.isChecked}
                          onChange={e => handleChange(e, 'checkbox')}
                        >
                          Remember me
                        </Checkbox>
                        <Link
                          to="/recoverPassword"
                          className="func-link text-xs"
                        >
                          Forgot password?
                        </Link>
                      </div>
                    </fieldset>
                  </form>
                  <div className="signin-bottom has-top-divider">
                    <div className="text-xs center-content text-color-low">
                      Don't you have an account?{' '}
                      <Link to="/signup" className="func-link">
                        Sign up
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

LoginForm.propTypes = propTypes;
LoginForm.defaultProps = defaultProps;

export default LoginForm;
