import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import Button from '../elements/Button'
import { Domain } from '../../utils/Config'
import { setCookie } from '../../utils/Cookie'
import { setLoginToken } from '../../utils/Login'
import { getNonceMessage, loginByPhantom } from '../../utils/Apis'

/**
 *
 * @param {*} message string
 */
async function getMetaMaskAccountSign (message) {
  // check if MetaMask is installed
  if (typeof window.ethereum !== 'undefined') {
    // prompt the user to connect their wallet
    try {
      const accounts = await window.ethereum.request({
        method: 'eth_requestAccounts'
      })
      const account = accounts[0]
      const sign = await window.ethereum.request({
        method: 'personal_sign',
        params: [message, account]
      })
      if (sign) {
        return {
          sign,
          account
        }
      }
    } catch (error) {
      toast.error(error)
    }
  } else {
    toast.error('MetaMask is not installed!')
  }
}

export default function LoginMetaMask ({ history }) {
  const [isLogin, setIsLogin] = useState(false)

  useEffect(() => {
    if (!isLogin) return
    signMessage()

    async function signMessage () {
      const message = await NonceMessage()
      if (!message) return
      const { account, sign } = await getMetaMaskAccountSign(message)

      if (account && sign) {
        loginByPhantom({
          msg: message,
          sign: sign,
          pubKey: account,
          wallet: 'Metamask'
        })
          .then(data => {
            if (data?.data?.loginByWeb3) {
              toast.success('Metamask Login Successfully!')
              setLoginToken(data.data.loginByWeb3)
              setCookie('token', data.data.loginByWeb3, -1, Domain)
              history.push('/')
            } else {
              toast.error(data?.errors[0]?.message ?? '')
            }
          })
          .catch(err => {
            toast.error(err)
            setIsLogin(false)
          })
      }
    }
  }, [isLogin, history])

  const NonceMessage = async () => {
    try {
      const data = await getNonceMessage()
      if (data?.data?.nonceMessage) {
        return data?.data.nonceMessage
      } else {
        toast.error(data?.errors[0]?.message)
        return false
      }
    } catch (err) {
      toast.error(err)
    }
    return false
  }

  return (
    <Button
      color='secondary'
      onClick={event => {
        setIsLogin(true)
        event.preventDefault()
      }}
    >
      MetaMask
    </Button>
  )
}
