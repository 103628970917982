import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Image from '../../elements/Image';
import logoSVG from '../../../assets/images/logo1.svg';
import logoImg from '../../../assets/images/logo1.png';

const Logo = ({ className, ...props }) => {
  const classes = classNames('brand', className);

  return (
    <div {...props} className={classes}>
      <h1 className="m-0">
        <Link to="/">
          <Image
            src={logoImg}
            alt="Storm"
            width={64}
            height={64}
          />
        </Link>
      </h1>
    </div>
  );
};

export const HeaderLogo = ({ className, ...props }) => {
  const classes = classNames('brand', className);

  return (
    <div {...props} className={classes}>
      <h1 className="m-0">
        <Link to="/">
          <Image
            src={logoSVG}
            alt="Storm"
            width={347}
            height={70}
          />
        </Link>
      </h1>
    </div>
  );
};

export default Logo;
