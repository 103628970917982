import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import Button from '../elements/Button'
import { Domain } from '../../utils/Config'
import { setCookie } from '../../utils/Cookie'
import { setLoginToken } from '../../utils/Login'
import { getNonceMessage, loginByPhantom } from '../../utils/Apis'

const getProvider = () => {
  if ('solana' in window) {
    const anyWindow = window
    const provider = anyWindow.solana
    if (provider.isPhantom) {
      return provider
    }
  }
  toast.error('phantom is not installed!')
  window.open('https://phantom.app/', '_blank')
}

/* const getMessage = () => {
  let message = ''
  message = window.navigator.userAgent + String(new Date().getTime())
  return message
} */

function buf2hex (buffer) {
  // buffer is an ArrayBuffer
  return [...new Uint8Array(buffer)]
    .map(x => x.toString(16).padStart(2, '0'))
    .join('')
}

export default function LoginPhantom ({ history }) {
  const [isLogin, setIsLogin] = useState(false)
  const [provider, setProvider] = useState()
  const [, setConnected] = useState(false)
  const [publicKey, setPublicKey] = useState(null)

  useEffect(() => {
    if (!isLogin) return
    const provider = getProvider()
    if (!provider) return
    setProvider(provider)

    provider.connect().catch(err => {
      setIsLogin(false)
      toast.error(err)
    })
    provider.on('connect', publicKey => {
      setPublicKey(publicKey?.toBase58())
      setConnected(true)
    })
    provider.on('disconnect', () => {
      setPublicKey(null)
      setConnected(false)
      setIsLogin(false)
      setLoginToken(null)
      toast.info('Disconnected')
    })
  }, [isLogin])

  const NonceMessage = async () => {
    try {
      const data = await getNonceMessage()
      if (data?.data?.nonceMessage) {
        return data?.data.nonceMessage
      } else {
        toast.error(data?.errors[0]?.message)
        return false
      }
    } catch (err) {
      toast.error(err)
    }
    return false
  }

  useEffect(() => {
    if (provider && publicKey) {
      signMessage()
      async function signMessage () {
        const message = await NonceMessage()
        if (!message) return

        try {
          const data = new TextEncoder().encode(message)
          const res = await provider.signMessage(data, 'utf-8')

          if (res?.publicKey) {
            loginByPhantom({
              msg: message,
              sign: buf2hex(res.signature),
              pubKey: publicKey,
              wallet: 'Phantom'
            })
              .then(data => {
                if (data?.data?.loginByWeb3) {
                  toast.success('Phantom Login Successfully!')
                  setLoginToken(data.data.loginByWeb3)
                  setCookie('token', data.data.loginByWeb3, -1, Domain)
                  history.push('/')
                } else {
                  toast.error(data?.errors[0]?.message ?? '')
                }
              })
              .catch(err => {
                toast.error(err)
                setIsLogin(false)
              })
          }
        } catch (err) {
          toast.error(err)
          setIsLogin(false)
        }
      }
    }
  }, [publicKey, provider, history])

  return (
    <Button
      color='secondary'
      onClick={event => {
        setIsLogin(true)
        event.preventDefault()
      }}
    >
      Phantom
    </Button>
  )
}
