import React from 'react';
import classNames from 'classnames';

const FooterSocial = ({ className, ...props }) => {
  const classes = classNames('footer-social', className);

  return (
    <div {...props} className={classes}>
      <ul className="list-reset">
        <li style={{ width: 50 }}>
          <a
            href="https://www.linkedin.com/company/zettavisor"
            target="_blank"
            rel="noreferrer"
            style={{ width: 84 }}
          >
            <svg
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Linkedin</title>
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.479 0H1.583C0.727 0 0 0.677 0 1.511V19.488C0 20.323 0.477 21 1.333 21H19.229C20.086 21 21 20.323 21 19.488V1.511C21 0.677 20.336 0 19.479 0ZM8 8H10.827V9.441H10.858C11.289 8.664 12.562 7.875 14.136 7.875C17.157 7.875 18 9.479 18 12.45V18H15V12.997C15 11.667 14.469 10.5 13.227 10.5C11.719 10.5 11 11.521 11 13.197V18H8V8ZM3 18H6V8H3V18ZM6.375 4.5C6.375 5.536 5.536 6.375 4.5 6.375C3.464 6.375 2.625 5.536 2.625 4.5C2.625 3.464 3.464 2.625 4.5 2.625C5.536 2.625 6.375 3.464 6.375 4.5Z"
              />
            </svg>
          </a>
        </li>
        <li>
          <a
            href="https://twitter.com/zettavisor"
            target="_blank"
            rel="noreferrer"
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Twitter</title>
              <path d="M16 3c-.6.3-1.2.4-1.9.5.7-.4 1.2-1 1.4-1.8-.6.4-1.3.6-2.1.8-.6-.6-1.5-1-2.4-1-1.7 0-3.2 1.5-3.2 3.3 0 .3 0 .5.1.7-2.7-.1-5.2-1.4-6.8-3.4-.3.5-.4 1-.4 1.7 0 1.1.6 2.1 1.5 2.7-.5 0-1-.2-1.5-.4C.7 7.7 1.8 9 3.3 9.3c-.3.1-.6.1-.9.1-.2 0-.4 0-.6-.1.4 1.3 1.6 2.3 3.1 2.3-1.1.9-2.5 1.4-4.1 1.4H0c1.5.9 3.2 1.5 5 1.5 6 0 9.3-5 9.3-9.3v-.4C15 4.3 15.6 3.7 16 3z" />
            </svg>
          </a>
        </li>
        <li>
          <a
            href="https://www.facebook.com/profile.php?id=100095118447015"
            target="_blank"
            rel="noreferrer"
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Facebook</title>
              <path d="M6.023 16L6 9H3V6h3V4c0-2.7 1.672-4 4.08-4 1.153 0 2.144.086 2.433.124v2.821h-1.67c-1.31 0-1.563.623-1.563 1.536V6H13l-1 3H9.28v7H6.023z" />
            </svg>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default FooterSocial;
