import Cookies from 'js-cookie';

export const setCookie = (name, value, expires, domain) => {
  Cookies.set(name, value, { expires, domain: domain || '' });
};

export const getCookie = (name) => {
  return Cookies.get(name);
};

export const removeCookie = (name) => {
  Cookies.remove(name);
};
