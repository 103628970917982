import React, { useRef, useEffect, useState } from 'react';
import * as d3 from 'd3';

const Colors = [
  { from: '#d629ff', to: '#7129ff' },
  { from: '#fe567f', to: '#fe84d0' },
  { from: '#1fcffd', to: '#6af1fe' },
  { from: '#476aff', to: '#56dbff' },
];

export default function RingComponent({ data = [], dimensions = {} }) {
  const svgRef = useRef(null);
  const { width = 300, height = 300, spacing = 8, margin = 26 } = dimensions;
  const [legend, setLegends] = useState([]);

  useEffect(() => {
    const N = d3.map(data, (d) => d.name);
    const V = d3.map(data, (d) => d.value);
    const I = d3.range(N.length).filter((i) => !isNaN(V[i]));
    const radius = Math.min(width, height) / 2 - margin;
    const bandWidth = Math.floor((radius - (I.length - 1) * spacing) / I.length);

    setLegends(N);

    const arcs = d3
      .pie()
      .sort(null)
      .value((i) => V[i])(I);
    const arc = d3
      .arc()
      .startAngle((d) => {
        return -d.startAngle;
      })
      .endAngle((d) => {
        return 6.3 - d.endAngle;
      })
      .innerRadius((_, i) => {
        return (radius - i * (spacing + bandWidth)) * 0.8;
      })
      .outerRadius((_, i) => {
        return (radius - i * (spacing + bandWidth) + bandWidth) * 0.8;
      });
    const outerArc = d3
      .arc()
      .startAngle((d, i) => {
        return i % 2 === 0 ? -d.startAngle : d.startAngle;
      })
      .endAngle((d, i) => {
        return i % 2 === 0 ? 6.3 - d.endAngle : d.endAngle;
      })
      .innerRadius((_, i) => {
        return radius * 1.1;
      })
      .outerRadius((_, i) => {
        return radius * 1.1;
      });

    const svg = d3.select(svgRef.current);
    svg.selectAll('*').remove(); // Clear svg content before adding new elements

    svg.attr('viewBox', [-width / 2, -height / 2, width, height]);

    // add defs linearGradient color
    svg
      .append('defs')
      .selectAll('linearGradient')
      .data(Colors)
      .join('linearGradient')
      .attr('id', (_, i) => `linear${i % Colors.length}`)
      .selectAll('stop')
      .data((d) => {
        return [d.from, d.to];
      })
      .join('stop')
      .attr('offset', (_, i) => {
        return i ? '90%' : '10%';
      })
      .attr('stop-color', (d) => d)
      .attr('stop-opacity', 1);

    svg
      .append('g')
      .attr('stroke', 'none')
      .attr('stroke-width', 1)
      .attr('stroke-linejoin', 'round')
      .selectAll('path')
      .data(arcs)
      .join('path')
      .attr('fill', (_, i) => {
        return `url(#linear${i % Colors.length})`;
      })
      .attr('d', arc)
      .on('mouseover', (dom, d) => {
        const target = dom.target;
        if (target) {
          const index = d.index;
          d3.select(target).style('opacity', 0.8);
          const polyline = d3.selectAll('polyline').filter((_, i) => i === index);
          polyline.style('opacity', 0.6).attr('stroke-width', 2);
        }
      })
      .on('mouseout', (dom, d) => {
        const target = dom.target;
        if (target) {
          const index = d.index;
          d3.select(target).style('opacity', 1);
          const polyline = d3.selectAll('polyline').filter((_, i) => i === index);
          polyline.style('opacity', 1).attr('stroke-width', 1);
        }
      });

    // Add the polylines between chart and labels
    svg
      .append('g')
      .attr('class', 'lineGroups')
      .selectAll('g')
      .data(arcs)
      .enter()
      .append('polyline')
      .attr('stroke', (_, i) => {
        return `url(#linear${i % Colors.length})`;
      })
      .style('fill', 'none')
      .attr('stroke-width', 1)
      .attr('points', function (d, i) {
        var posA = arc.centroid(d, i); // line insertion in the slice
        var posB = outerArc.centroid(d, i); // line break: we use the other arc generator that has been built only for that
        var posC = outerArc.centroid(d, i); // Label position = almost the same as posB
        var midangle = -d.startAngle + (6.3 - d.endAngle + d.startAngle) / 2; // we need the angle to see if the X position will be at the extreme right or extreme left
        posC[0] = radius * 1.12 * (midangle > 0 ? 1 : -1); // multiply by 1 or -1 to put it on the right or on the left
        return [posA, posB, posC];
      });

    // Add the polylines between chart and labels
    svg
      .append('g')
      .selectAll('g')
      .data(arcs)
      .enter()
      .append('text')
      .text((d, i) => {
        const total = V.reduce((a, b) => a + b);
        return `${N[i].slice(0, 3)}:${((d.value / total) * 100).toFixed(0)} %`;
      })
      .attr('font-size', '12')
      .attr('transform', (d, i) => {
        var pos = outerArc.centroid(d, i);
        var midangle = -d.startAngle + (6.3 - d.endAngle + d.startAngle) / 2;
        pos[0] = radius * 0.8 * (midangle > 0 ? 1 : -1);
        pos[1] = pos[1] - 1;
        return 'translate(' + pos + ')';
      })
      .style('text-anchor', (d) => {
        var midangle = -d.startAngle + (6.3 - d.endAngle + d.startAngle) / 2;
        return midangle > 0 ? 'start' : 'end';
      })
      .attr('fill', (_, i) => {
        return `#fff`;
      });
    // eslint-disable-next-line
  }, []);

  return (
    <div className='animate__animated animate__zoomIn' style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'end' }}>
      <svg ref={svgRef} width={width} height={height}></svg>
      <ul className='legend'>
        {legend.map((item, i) => {
          const index = i % Colors.length;
          return (
            <li key={i} style={{ color: Colors[index].from }}>
              <span style={{ background: `linear-gradient(to left, ${Colors[index].from}, ${Colors[index].to})` }}></span>
              {item}
            </li>
          );
        })}
      </ul>
    </div>
  );
}
