import React from 'react';
import HeroFull from '../components/sections/HeroFull';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import FeaturesSplit from '../components/sections/FeaturesSplit';
// import Marketing from '../components/sections/Marketing';

class Home extends React.Component {
  constructor (props) {
    super(props);

    const winWidth = window.innerWidth;

    this.state = {
      invertMobile: winWidth <= 640 ? true : false
    };
  }

  render () {
    return (
      <React.Fragment>
        <HeroFull
          invertMobile={this.state.invertMobile}
          className="illustration-section-01"
        />
        <FeaturesTiles className="illustration-section-02" />
        <FeaturesSplit
          invertMobile={this.state.invertMobile}
          topDivider
          imageFill
        />
        {/* <Marketing topDivider /> */}
      </React.Fragment>
    );
  }
}

export default Home;
