import React from 'react';
import img_001 from './images/001.jpg';
import img_001_1 from './images/001_1.png';
import img_001_2 from './images/001_2.png';

export default function Blog_001 () {
  return (
    <React.Fragment>
      <h1>
        Discover TimeGuard: Secure Resource Protection with Proof of Time
        Algorithm
      </h1>

      <div className="des">
        Looking to enhance resource protection and ensure fair access in
        high-concurrency distributed systems? Dive into our latest article,
        where we unveil the innovative TimeGuard algorithm
      </div>

      <img src={img_001} alt="TimeGuard" />

      <section>
        <h4>Section 1: Use Cases</h4>
        <p>
          Maintaining system state in high-concurrency distributed systems is a
          critical and resource-intensive task. The TimeGuard algorithm finds
          its applications in various scenarios, including:
        </p>
        <p className="padding-tighten">
          1. Timed Product Purchasing: Ensuring fair access to limited-quantity
          products by allowing users to make purchases only at specific time
          intervals.
        </p>
        <p className="padding-tighten">
          2. Voucher Distribution: Managing the distribution of vouchers or
          coupons by enforcing time-based redemption limitations.
        </p>
        <p className="padding-tighten">
          3. AI System Workload Management: Regulating user requests to AI
          systems that involve resource-intensive operations, such as language
          model inference and analytical tasks, by imposing time-based
          constraints.
        </p>
        <p>
          In these use cases, TimeGuard utilizes the inherent
          non-parallelizability of the Proof of Elapsed Time (PoET) algorithm to
          ensure that users must wait for a specified time period before
          submitting subsequent requests. The Verify method is employed to
          quickly validate the user's Proof and determine its correctness. The
          subsequent sections of this document will delve into the detailed
          design and implementation of TimeGuard.
        </p>
        <h4>Section 2: Implementation Details</h4>
        <h5>2.1 Background: Information Digest Algorithm</h5>
        <p>
          Before diving into the specifics of the TimeGuard algorithm
          implementation, it is essential to understand the concept of an
          information digest algorithm. An information digest algorithm, also
          known as a hash function, generates a unique fixed-size output
          (digest) for any given input data. This algorithm ensures that even a
          minor change in the input will produce a significantly different
          digest. Information digest algorithms are widely used for data
          integrity verification and cryptographic applications.
        </p>
        <h5>2.2 Implementation Overview</h5>
        <p>The implementation of TimeGuard involves the following steps:</p>
        <p className="padding-tighten">
          1. Identifying Critical Information: Start by identifying the key
          information associated with the protected object, such as voucher
          codes or IMEI numbers for purchasing limited-quantity items. These
          critical pieces of information will serve as parameters for the
          information digest algorithm.
        </p>
        <p className="padding-tighten">
          2. Adding Business Parameters: Combine the critical information with
          relevant business parameters, such as the start time of an event or
          activity. These additional parameters help create a unique context for
          each transaction.
        </p>
        <p className="padding-tighten">
          3. Information Digest Calculation: Apply the information digest
          algorithm to the combined factors obtained in the previous step. This
          calculation generates a unique digest for the given parameters,
          ensuring the integrity and non-repudiation of the protected object.
        </p>
        <p className="padding-tighten">
          4. Proof of Elapsed Time (PoET) Parameters: Utilize the calculated
          information digest as a parameter for the PoET algorithm.
          Additionally, include the desired time delay that users must wait
          before submitting the next request.
        </p>
        <p className="padding-tighten">
          5. Initiating PoET Process: Transmit the PoET parameters, including
          the information digest and the specified time delay, to the client for
          execution. The client will commence the PoET process based on the
          provided parameters.
        </p>
        <p>
          It is important to highlight that the security of the algorithm is
          mathematically proven, ensuring that users must wait for the
          designated duration as specified by the server before making
          subsequent requests.
        </p>
        <p>
          In the following sections, we will delve deeper into the mathematical
          foundations, implementation considerations, and potential
          optimizations of the TimeGuard algorithm.
        </p>
        <img src={img_001_1} alt="" />
        <p className="text-center">
          Figure 1: Server preparation process for PoET algorithm
        </p>
        <img src={img_001_2} alt="" />
        <p className="text-center">
          Figure 2: The client-server interaction process of the PoET algorithm
        </p>
        <h4>Section 3: Technical References</h4>
        <h5>3.1 Implementation Details</h5>
        <p>
          For the specific implementation of Proof of Elapsed Time (PoET), we
          leverage a partial implementation of VDFs (Verifiable Delay Functions)
          from the Chia blockchain project. The algorithmic entry point for our
          implementation can be referenced in the following code example:
        </p>
        <a href="https://github.com/Chia-Network/vdf-competition/blob/main/vdf.cpp#L22.ƒ">
          https://github.com/Chia-Network/vdf-competition/blob/main/vdf.cpp#L22.
        </a>
        <p>
          In practical engineering, we recommend utilizing the
          <a href="https://pypi.org/project/chiavdf/ ">
            https://pypi.org/project/chiavdf/{' '}
          </a>
          package or other language-specific methods such as CGO in Golang or
          FFI in Rust to wrap the API for seamless integration.
        </p>
        <h5>3.2 Mathematical Proof Reference</h5>
        <p>
          For a comprehensive understanding of the mathematical foundations and
          proof behind the TimeGuard algorithm, we refer to the following
          research paper:
        </p>
        <a href="https://github.com/Chia-Network/vdf-competition/blob/main/classgroups.pdf.">
          https://github.com/Chia-Network/vdf-competition/blob/main/classgroups.pdf.
        </a>
        <p>
          This paper provides detailed insights into the theoretical
          underpinnings, including class groups and related concepts, supporting
          the security and non-repudiation aspects of the algorithm.
        </p>
        <p>
          In the subsequent sections, we will further explore the performance
          considerations, potential optimizations, and real-world applications
          of TimeGuard.
        </p>
        <h4>Section 4: Conclusion</h4>
        <p>
          TimeGuard presents an innovative approach to secure resource
          protection using the Proof of Time (PoET) algorithm. By leveraging the
          inherent non-parallelizability of PoET and combining it with
          information digest algorithms, TimeGuard ensures that users must
          adhere to specified time intervals before submitting subsequent
          requests. This solution effectively safeguards critical resources in
          high-concurrency distributed systems.
        </p>
        <p>
          In this documentation, we have discussed the various use cases where
          TimeGuard can be applied, including timed product purchasing, voucher
          distribution, and workload management in AI systems. By enforcing
          time-based constraints, TimeGuard offers fair access, prevents abuse,
          and enhances resource allocation efficiency.
        </p>
        <p>
          The implementation details shed light on the process of calculating
          information digests, incorporating business parameters, and utilizing
          the Chia blockchain's VDF implementation for PoET. References to the
          Chia project's code examples and mathematical proof documents provide
          further technical insights and research references.
        </p>
        <p>
          TimeGuard offers a reliable and mathematically proven approach to
          protect critical resources in distributed systems, ensuring the
          integrity, fairness, and controlled access to shared resources. Its
          flexibility allows integration into various applications and
          environments.
        </p>
        <p>
          As the TimeGuard algorithm continues to evolve, ongoing research and
          engineering efforts will focus on optimizing performance, enhancing
          scalability, and expanding compatibility with different programming
          languages and frameworks.
        </p>
        <p>
          By adopting TimeGuard, organizations and developers can establish a
          robust resource protection mechanism that maintains system stability,
          prevents abuse, and ensures a fair and secure user experience.
        </p>
        <p>
          Zettavisor Corporation has successfully implemented this method to
          help users achieve the desired outcomes. Both the content of this
          document and the company's original code design are protected by
          copyright.
        </p>
      </section>
    </React.Fragment>
  );
}
