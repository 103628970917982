import { initializeApp } from 'firebase/app';
import { getFirestore, collection, addDoc, Timestamp } from 'firebase/firestore';
import { toast } from 'react-toastify';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyAaXyyfX1-k3zwVyW3loHw3tn7_cG5IaMM',
  authDomain: 'zettavisor-ee99b.firebaseapp.com',
  projectId: 'zettavisor-ee99b',
  storageBucket: 'zettavisor-ee99b.appspot.com',
  messagingSenderId: '373830021210',
  appId: '1:373830021210:web:4197eb156e7754e66ce863'
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);

export const SaveGuest = async data => {
  try {
    SendMessageToTelegram(data);
    const docRef = await addDoc(collection(db, 'guests'), {
      ...data,
      created: Timestamp.now()
    });
    toast.success(
      'Thank you for your trust, we will contact you as soon as possible.'
    );
    return docRef;
  } catch (error) {
    toast.error(JSON.stringify(error));
    return false;
  }
};

export const SendMessageToTelegram = params => {
  const data = new FormData();
  data.append('chat_id', -917288236); // 1051010914
  data.append(
    'text',
    `You have a new client, Please contact them as soon as possible.
    
    <b>${params?.description ?? ''}</b>`
  );
  data.append('parse_mode', 'HTML');
  fetch(
    'https://api.telegram.org/bot6371001195:AAFKl4qpQ7Wbxyp-IHeJSpYTXfYg6yYdphI/sendMessage',
    {
      method: 'POST',
      'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
      body: data
    }
  )
    .then(response => {
      if ((response?.status ?? '') !== 200) {
        return response;
      }
      return response.json();
    })
    .then(data => {
      console.log(data);
      return data;
    })
    .catch(error => {
      return error;
    });
};

// get chat_id
export const UpdateTelegram = () => {
  fetch(
    'https://api.telegram.org/bot6371001195:AAFKl4qpQ7Wbxyp-IHeJSpYTXfYg6yYdphI/getUpdates',
    {
      method: 'GET',
      'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8'
    }
  )
    .then(response => {
      if ((response?.status ?? '') !== 200) {
        return response;
      }
      return response.json();
    })
    .then(data => {
      console.log(data);
      return data;
    })
    .catch(error => {
      return error;
    });
};
