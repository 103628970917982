import React from 'react';
import Input from '../elements/Input';
import Button from '../elements/Button';
import './ContactUs.css';
import { SaveGuest } from '../../utils/Firebase';

class ContactUs extends React.Component {
  state = {
    forms: { email: '', name: '', description: '' },
    loading: false
  };

  render () {
    const { show, handleClose } = this.props;
    const handleChange = e => {
      let value = e.target.value;
      this.setState({ forms: { ...this.state.forms, [e.target.name]: value } });
    };

    const saveGuest = async () => {
      this.setState({ loading: true });
      const data = await SaveGuest(this.state.forms);
      if (data) {
        this.setState({
          forms: { email: '', name: '', description: '' },
          loading: false
        });
      } else {
        this.setState({ loading: false });
      }
    };

    return (
      <div
        className="contact_form"
        style={{
          opacity: show ? 1 : 0,
          right: show ? '0' : '-400px'
        }}
      >
        <form
          onSubmit={e => {
            if (!this.state.loading) {
              saveGuest();
            }
            e.preventDefault();
          }}
        >
          <fieldset>
            <div className="mb-12">
              <Input
                label="* NAME"
                type="text"
                name="name"
                value={this.state.forms.name}
                placeholder="Please input your company name"
                onChange={handleChange}
                required
              />
            </div>

            <div className="mb-12">
              <Input
                label="* EMAIL"
                type="email"
                name="email"
                value={this.state.forms.email}
                placeholder="Please input your company email"
                onChange={handleChange}
                required
              />
            </div>

            <div className="mb-12">
              <Input
                label="DESCRIBE YOUR COMPANY"
                type="textarea"
                name="description"
                value={this.state.forms.description}
                rows={8}
                placeholder="Please add your company information"
                onChange={handleChange}
              />
            </div>

            <div className="pt-32 text-xs text-color-low">
              <Button color="primary">
                {this.state.loading ? 'Loading...' : 'SUBMIT'}
              </Button>
            </div>
          </fieldset>
        </form>

        <button
          className="modal-close"
          aria-label="close"
          onClick={handleClose}
        ></button>
      </div>
    );
  }
}

export default ContactUs;
