import React from 'react';
import Button from '../../components/elements/Button';
import './index.css';
import ContactUs from '../../components/sections/ContactUs';

import technicalConsultant from './images/technicalConsultant.png';
import proactiveMaintenance from './images/proactiveMaintenance.png';
import securite from './images/securite.png';
import strategy from './images/strategy.png';
import tailoredDevelopment from './images/tailoredDevelopment.png';
import scale from './images/scale.png';
import whyUs from './images/whyUs.png';
import emailUs from './images/emailUs.png';

class BusinessPlan extends React.Component {
  state = {
    showContact: false
  };

  render () {
    return (
      <div>
        <div
          className="business_header"
          style={{
            width: '100%',
            position: 'relative',
            overflow: 'hidden'
          }}
        >
          <div
            className={`reveal-from-bottom`}
            style={StartButton}
            data-reveal-delay="600"
          >
            <h2>
              Elevating Possibilities <br />
              Internet Infrastructure Mastery at Your Service
            </h2>
            <Button
              color="primary"
              onClick={event => {
                this.setState({ showContact: true });
              }}
            >
              RESERVE
            </Button>
          </div>
        </div>

        <section className="section_services">
          <h1>SERVICES</h1>

          <div className="section_service">
            <div className="section_item reveal-from-left">
              <img src={technicalConsultant} alt="technical consultant" />

              <div>
                <h5>Technical Consultations</h5>
                <p className="text-xxs">
                  Get custom insights and guidance for your unique internet
                  setup. Specialists evaluate, recommend to boost performance.
                </p>
              </div>
            </div>

            <div className="section_item reveal-scale-up">
              <img src={strategy} alt="strategy implementation" />

              <div>
                <h5>Strategic Implementation</h5>
                <p className="text-xxs">
                  Plan, execute robust internet solutions aligning with goals.
                  Transition seamlessly to upgraded systems, optimized data.
                </p>
              </div>
            </div>

            <div className="section_item reveal-from-right">
              <img
                src={proactiveMaintenance}
                alt="proactive maintenance projects"
              />

              <div>
                <h5>Proactive Maintenance Projects</h5>
                <p className="text-xxs">
                  Sustain digital efficiency with proactive maintenance.
                  Monitor, update internet setup for smooth operations, risk
                  reduction.
                </p>
              </div>
            </div>

            <div className="section_item reveal-from-left">
              <img src={tailoredDevelopment} alt="tailored development" />

              <div>
                <h5>Tailored Development Projects</h5>
                <p className="text-xxs">
                  Enhance capabilities with custom projects leveraging internet
                  potential: network expansion, cloud optimization, tech
                  integration.
                </p>
              </div>
            </div>

            <div className="section_item_img reveal-scale-up">
              <img src={scale} alt="scale" />
            </div>

            <div className="section_item reveal-from-right">
              <img src={securite} alt="comprehensive security solutions" />

              <div>
                <h5>Comprehensive Security Solutions</h5>
                <p className="text-xxs">
                  Secure assets with comprehensive services: assess, implement
                  advanced measures, ongoing monitoring for resilient internet.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="section_why">
          <div className="section_reasons">
            <img className="reveal-rotate-from-left" src={whyUs} alt="why us" />

            <div className="section_content reveal-scale-up">
              <div>
                <h5>Expert Insight</h5>
                <p className="text-xxs">
                  Get unparalleled expertise to optimize your internet
                  infrastructure.
                </p>
              </div>
              <div>
                <h5>Tailored Solutions</h5>
                <p className="text-xxs">
                  Customized strategies that perfectly align with your business
                  goals.
                </p>
              </div>
              <div>
                <h5>Seamless Growth</h5>
                <p className="text-xxs">
                  Elevate performance, ensuring smooth scaling and digital
                  advancement.
                </p>
              </div>
            </div>
          </div>
        </section>

        <div className="email_us">
          {/* <a href="mailto:qingling.xu@zettavisor.com"> */}
          <img
            src={emailUs}
            alt="email us"
            onClick={() => {
              this.setState({ showContact: !this.state.showContact });
            }}
          />
          {/* </a> */}

          <ContactUs
            show={this.state.showContact}
            handleClose={() => {
              this.setState({ showContact: false });
            }}
          />
        </div>
      </div>
    );
  }
}

const StartButton = {
  position: 'absolute',
  bottom: '6%',
  left: '6%',
  fontSize: '32px',
  textAlign: 'left'
};

export default BusinessPlan;
