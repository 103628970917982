import { Domain } from './Config';
import { setCookie, getCookie } from './Cookie';

export const setLoginToken = (token) => {
  sessionStorage.setItem('token', token);
};

export const getLoginToken = () => {
  return sessionStorage.getItem('token') || getCookie('token') || '';
};

export const logout = () => {
  setLoginToken('');
  setCookie('rememberMe', '', -10, Domain);
  setCookie('token', '', -10, Domain);
};
