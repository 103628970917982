import React from 'react';
import './index.scss';
import img_01 from './images/01.jpg';
import img_02 from './images/02.jpg';
import img_03 from './images/03.jpeg';
import img_04 from '../Blog/images/004.png';
import img_05 from '../Blog/images/005_5.png';

class Blogs extends React.Component {
  constructor (props) {
    super(props);

    const winWidth = window.innerWidth;

    this.state = {
      invertMobile: winWidth <= 640 ? true : false,
      list: [
        {
          img: img_05,
          img_alt: 'Enhancing User Experience: A Comprehensive Guide to Leveraging Zettavisor for Optimal Data Analysis',
          title:
            'Enhancing User Experience: A Comprehensive Guide to Leveraging Zettavisor for Optimal Data Analysis',
          date: '2024-01-22',
          desc: "The article talks about leveraging Zettavisor for optimal data analysis to improve user experience. It discusses the challenges faced by users while analyzing time-series data, and presents solutions offered by Zettavisor. The article provides an in-depth understanding of key terminologies like time-series data, sliding window, aggregation function, thresholding, and data model matching. It goes on to explain how Zettavisor works in practice with visual illustrations and describes the innovative features developed to assist users in data analysis. It concludes by sharing future plans of implementing advanced AI technology for a chat assistant and further enhancements to the data filter condition editor. The piece emphasizes the commitment of Zettavisor to continually innovate and improve to provide comprehensive and user-friendly solutions to users.",
          id: '005'
        },
        {
          img: img_04,
          img_alt: 'Comprehensive Services for Local Small and Medium Enterprises',
          title:
            'Comprehensive Services for Local Small and Medium Enterprises: A Clear and Concise Guide.',
          date: '2024-01-14',
          desc: "This guide explains methods for providing comprehensive services to local SMEs, focusing on IT assistance, operational efficiency enhancement, and market competitiveness. It details a three-step approach encompassing consultation, digital system implementation, elaborates on efficiency enhancement areas, and shares client case studies.",
          id: '004'
        },
        {
          img: img_03,
          img_alt: 'Revolutionizing Automotive Repair Management',
          title:
            '🚀 Revolutionizing Automotive Repair Management: Our Comprehensive SaaS Platform is Here! 🛠️',
          date: '2023-10-20',
          desc: "Our Comprehensive SaaS Platform is Here! 🛠️ Exciting news for the automotive repair community! We're in the process of creating a cutting-edge SaaS platform tailored for automotive repair businesses, equipped with the latest features...",
          id: '003'
        },
        {
          img: img_02,
          img_alt: 'LLMs',
          title:
            'Engineering Practices of Natural Language Classification with the Power of Large Language Models in Artificial Intelligence',
          date: '2023-07-03',
          desc: 'Exploring the power and potential of Large Language Models (LLMs) in the realm of Natural Language Classification for strategic and impactful business decision-making...',
          id: '002'
        },
        {
          img: img_01,
          img_alt: 'Discover TimeGuard',
          title:
            'Discover TimeGuard: Secure Resource Protection with Proof of Time Algorithm',
          date: '2023-04-15',
          desc: 'Looking to enhance resource protection and ensure fair access in high-concurrency distributed systems? Dive into our latest article, where we unveil the innovative TimeGuard algorithm...',
          id: '001'
        }
      ]
    };
  }

  render () {
    return (
      <React.Fragment>
        <section className="section illustration-section-01">
          <div className="container blogs-container">
            <h1>What's Next?</h1>

            <div className="des">
              Embark on a journey through time and innovation as we unfold the
              chapters of our rich development history and present exciting
              updates on our latest project. At Zettavisor, our story is one of
              growth, challenges, and continuous exploration. Join us on this
              blog page to witness the evolution of our company and get an
              exclusive glimpse into our newest venture.
            </div>

            {this.state.list.map((item, index) => (
              <div className="blog-wrap" key={index}>
                <img
                  className="blog-wrap-item reveal-from-left"
                  src={item.img}
                  alt={item.img_alt}
                  width={495}
                />
                <div className="blog-wrap-item reveal-from-right">
                  <a href={`/blog/${item.id}`}><h4>{item.title}</h4> </a>
                  <p>{item.date}</p>
                  <p className='blog-desc'>{item.desc}</p>

                  <button
                    onClick={() => {
                      this.props.history.push(`/blog/${item.id}`);
                    }}
                  >
                    SEE MORE
                  </button>
                </div>
              </div>
            ))}
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Blogs;
