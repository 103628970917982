import React from 'react';
import { withRouter, Switch } from 'react-router-dom';
import AppRoute from './utils/AppRoute';
import ScrollReveal from './utils/ScrollReveal';

// Layouts
import LayoutDefault from './layouts/LayoutDefault';
import LayoutSignin from './layouts/LayoutSignin';

// Views
import Home from './views/Home';
import Login from './views/Login';
import Signup from './views/Signup';
import RecoverPassword from './views/RecoverPassword';
import PrivacyPolicy from './views/PrivacyPolicy';
import Dashboard from './views/Dashboard';
import BusinessPlan from './views/ToBusiness';
import Blogs from './views/Blogs';
import Blog from './views/Blog';

class App extends React.Component {
  componentDidMount () {
    document.body.classList.add('is-loaded');
    this.refs.scrollReveal.init();
  }

  // Route change
  componentDidUpdate (prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.refs.scrollReveal.init();
    }
  }

  render () {
    const { history } = this.props;
    const props = { history };

    return (
      <ScrollReveal
        ref="scrollReveal"
        children={() => (
          <Switch>
            <AppRoute
              exact
              path="/"
              component={Home}
              layout={LayoutDefault}
              {...this.props}
            />
            <AppRoute
              exact
              path="/login"
              component={Login}
              layout={LayoutDefault}
              {...props}
            />
            <AppRoute
              exact
              path="/signup"
              component={Signup}
              layout={LayoutDefault}
              {...this.props}
            />
            <AppRoute
              exact
              path="/recoverPassword"
              component={RecoverPassword}
              layout={LayoutDefault}
              {...this.props}
            />
            <AppRoute
              exact
              path="/privacyPolicy"
              component={PrivacyPolicy}
              layout={LayoutDefault}
              {...this.props}
            />
            <AppRoute
              exact
              path="/dashboard"
              component={Dashboard}
              layout={LayoutSignin}
            />
            <AppRoute
              path="/business"
              component={BusinessPlan}
              layout={LayoutDefault}
            />
            <AppRoute
              path="/blogs"
              component={Blogs}
              layout={LayoutDefault}
              {...props}
            />
            <AppRoute
              path="/blog/:id"
              component={Blog}
              layout={LayoutDefault}
              {...props}
            />
          </Switch>
        )}
      />
    );
  }
}

const AppRouter = withRouter(props => <App {...props} />);
export default AppRouter;
