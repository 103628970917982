import React from 'react'
import classNames from 'classnames'
import { SectionTilesProps } from '../../utils/SectionProps'
import SectionHeader from './partials/SectionHeader'
import Image from '../elements/Image'
import icon1SVG from './../../assets/images/feature-tile-icon-04.svg'
import icon5SVG from './../../assets/images/feature-tile-icon-05.svg'
import icon6SVG from './../../assets/images/feature-tile-icon-03.svg'

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

class FeaturesTiles extends React.Component {
  render () {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...props
    } = this.props

    const outerClasses = classNames(
      'features-tiles section center-content',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    )

    const innerClasses = classNames(
      'features-tiles-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    )

    const tilesClasses = classNames('tiles-wrap', pushLeft && 'push-left')

    const sectionHeader = {
      title: '',
      paragraph: ''
    }

    return (
      <section {...props} className={outerClasses}>
        <div className='container'>
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className='center-content' />
            <div className={tilesClasses}>
              <div className='tiles-item reveal-scale-up'>
                <div className='tiles-item-inner'>
                  <div className='features-tiles-item-header'>
                    <div className='features-tiles-item-image mb-16'>
                      <Image
                        src={icon1SVG}
                        alt='Features tile icon 01'
                        width={80}
                        height={80}
                      />
                    </div>
                  </div>
                  <div className='features-tiles-item-content'>
                    <h4 className='mt-0 mb-8'>Extensive</h4>
                    <p className='m-0 text-sm'>
                      Customizable reporting allows you to set, measure, and
                      achieve your goals.
                    </p>
                  </div>
                </div>
              </div>

              <div
                className='tiles-item reveal-scale-up'
                data-reveal-delay='200'
              >
                <div className='tiles-item-inner'>
                  <div className='features-tiles-item-header'>
                    <div className='features-tiles-item-image mb-16'>
                      <Image
                        src={icon5SVG}
                        alt='Features tile icon 05'
                        width={80}
                        height={80}
                      />
                    </div>
                  </div>
                  <div className='features-tiles-item-content'>
                    <h4 className='mt-0 mb-8'>User friendly</h4>
                    <p className='m-0 text-sm'>
                      Designed to be the easiest data analysis software to learn
                      to use and manage.
                    </p>
                  </div>
                </div>
              </div>

              <div
                className='tiles-item reveal-scale-up'
                data-reveal-delay='400'
              >
                <div className='tiles-item-inner'>
                  <div className='features-tiles-item-header'>
                    <div className='features-tiles-item-image mb-16'>
                      <Image
                        src={icon6SVG}
                        alt='Features tile icon 06'
                        width={80}
                        height={80}
                      />
                    </div>
                  </div>
                  <div className='features-tiles-item-content'>
                    <h4 className='mt-0 mb-8'>Real-Time</h4>
                    <p className='m-0 text-sm'>
                      Quickly input data, find information, and customize
                      reports.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

FeaturesTiles.propTypes = propTypes
FeaturesTiles.defaultProps = defaultProps

export default FeaturesTiles
