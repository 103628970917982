import React from 'react';
import RecoverPasswordForm from '../components/sections/RecoverPasswordForm';

class RecoverPassword extends React.Component {
  render() {
    return <RecoverPasswordForm className='illustration-section-01' history={this.props.history} />;
  }
}

export default RecoverPassword;
