import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { SectionProps } from '../../utils/SectionProps';
import Button from '../elements/Button';
import { setCookie, getCookie } from '../../utils/Cookie';

const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool
};

const defaultProps = {
  ...SectionProps.defaults,
  split: false
};

class Cookie extends React.Component {
  state = {
    visible: false,
    hasCookie: false
  };

  componentDidMount () {
    const hasCookie = getCookie('cookiePolicy');
    this.setState({ hasCookie });
  }

  render () {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      split,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'Cookie section center-content',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    return (
      <section
        {...props}
        className={outerClasses}
        style={{ display: this.state.hasCookie ? 'none' : 'flex' }}
      >
        <div className="cookie">
          <p>We uses cookies to enhance the user experience.</p>

          <div>
            <Button
              onClick={() => {
                setCookie('cookiePolicy', 'true', 90);
                this.setState({ visible: false, hasCookie: true });
              }}
            >
              ACCEPT COOKIES
            </Button>

            <Link target="_blank" to="/privacyPolicy">
              Privacy Policy
            </Link>
          </div>
        </div>
      </section>
    );
  }
}

Cookie.propTypes = propTypes;
Cookie.defaultProps = defaultProps;

export default Cookie;
