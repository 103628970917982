import React, { Fragment, useRef, useEffect, useState } from 'react';
import { getAllEncrypt } from '../../../utils/Apis';
import { Config } from './config';

const BubbleImg = require('./bubble.png');

const MaxHeight = 450;
const SI = {};

let loopIndex = 30;
let ST = null;

const positions = {};

function getRandom(min, max) {
  return Math.floor(Math.random() * (max - min) + min);
}

function getStyleHtml(width, left, bottom) {
  let sty = `transform: translate(0, 0);`;
  if (width) {
    sty += `width: ${width}px;`;
    sty += `height: ${width}px;`;
  }
  left && (sty += `left: ${left}px;`);
  bottom && (sty += `bottom: ${bottom}px;`);
  return sty;
}

function BubbleHTML(options) {
  const { value, id, unit, name, color, width } = options;
  const upperName = name.toUpperCase();
  const innerHtml = `<div class="animate__animated animate__bounceInUp">
    <img src='${BubbleImg}' alt='' />
    <p class='text' style="color:${color};">${value} ${unit}</p>
    
    <div class="circular">
      <svg width="${width * 1.3}px" height="${width * 1.3}px" viewBox="0 0 140 75">
        <g transform='translate(-508.000000, -349.000000)' stroke='none'>
          <path
            d='M508.30008,349 C510.062768,354.237739 511.267173,357.808197 511.913295,359.711373 C513.23323,363.59929 514.932519,365.943424 515.974633,367.877149 C520.684029,376.615805 526.849911,381.553443 531.193999,385.185651 C535.080264,388.435059 540.321173,391.79213 547.683541,394.105627 C551.420032,395.279754 558.159822,396.577879 567.902913,398 C576.634228,396.584208 582.710494,395.286083 586.131711,394.105627 C595.857604,390.749804 602.319864,385.935025 606.822607,382.078202 C611.779813,377.83211 618.399362,370.579167 622.543161,361 C623.613907,358.524771 624.469243,354.524771 625.109171,349'
            id='circle'
          ></path>
        </g>
        <text transform='translate(-496.000000, -320.000000)'>
          <textPath xlink:href="#circle">
            ${upperName}
          </textPath>
        </text>
      </svg>
    </div>
  </div>
  `;
  const objDiv = document.createElement('div');
  objDiv.className = 'bubble';
  objDiv.id = id;
  objDiv.innerHTML = innerHtml;
  return objDiv;
}

function addAnimation(options, callback) {
  const { width, left, bottom, id } = options;
  const current = document.getElementById(id);
  let y = 0;
  positions[id] = { l: left, b: bottom, d: width, x: 0, y: 0 };
  current.style = getStyleHtml(Math.round(width * 1.3), left, bottom);
  SI[id] && clearInterval(SI[id]);
  SI[id] = setInterval(() => {
    if (current && Math.abs(y) + bottom <= MaxHeight) {
      y -= Math.random().toFixed(1);
      current.style.setProperty('transform', `translate(0, ${y}px)`);
      positions[id].y = y;
    } else {
      y = 0;
      clearInterval(SI[id]);
      SI[id] = null;
      delete SI[id];
      delete positions[id];
      current.remove();
      callback();
    }
  }, 20);
}

function getLeftBottom(diameter) {
  return new Promise((resolve) => {
    const keys = Object.keys(positions);
    let loopTimes = 50;
    let isCover = true;
    let left = 0;
    let bottom = 0;

    while (isCover) {
      isCover = false;
      loopTimes--;
      left = getRandom(10, 700) || 0;
      bottom = getRandom(0, 80) || 0;
      for (let i = 0, len = keys.length; i < len; i++) {
        const pos = positions[keys[i]];
        const distance = Math.round((diameter + pos.d) / 2);
        if (Math.abs(pos.l - left) > distance) continue;
        if (Math.abs(pos.b - pos.y - bottom) > distance) continue;
        isCover = true;
        break;
      }
      if (loopTimes <= 0) {
        isCover = false;
      }
    }
    if (!isCover) {
      resolve({ left, bottom });
    }
  });
}

async function getAttr(i, oneData) {
  const { left, bottom } = await getLeftBottom(oneData.width);

  return { ...oneData, left, bottom, id: new Date().getTime() + String(i) };
}

export default function Bubbles() {
  const [data, setData] = useState([]);
  const ref = useRef(null);

  useEffect(() => {
    getAllEncrypt().then((data) => {
      const lists = data?.results[0]?.series;
      const listCapitals = [];
      lists.forEach((list) => {
        if (list.values[0][2] > 0) {
          list.capLen = list.values[0][2].toFixed(0).length;
          const category = Config[list.capLen];
          listCapitals.push({ ...category, name: list.tags.id, value: (list.values[0][2] / category.divide).toFixed(2) });
        }
      });
      setData(listCapitals);
    });
  }, []);

  const generateNew = async () => {
    loopIndex++;
    if (loopIndex >= data.length) {
      loopIndex = 0;
    }
    const attr = await getAttr(loopIndex, data[loopIndex]);
    ref.current.appendChild(BubbleHTML(attr));
    addAnimation(attr, generateNew);
  };

  const initialBubbles = async (i) => {
    if (i > loopIndex) return;
    const attr = await getAttr(i, data[i]);
    ref.current.appendChild(BubbleHTML(attr));
    addAnimation(attr, generateNew);
    ST = setTimeout(() => {
      clearTimeout(ST);
      ST = null;
      initialBubbles(i + 1);
    }, 400);
  };

  useEffect(() => {
    if (data && data.length) {
      initialBubbles(0);
    }
    // eslint-disable-next-line
  }, [data]);

  return (
    <Fragment>
      <div className='bubbles' ref={ref}></div>
    </Fragment>
  );
}
