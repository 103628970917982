import React, { useEffect } from 'react';

var Highcharts = require('highcharts');

export default function HighChart({ categories, volumeData, rangeData }) {
  useEffect(() => {
    require('highcharts/modules/exporting')(Highcharts);

    Highcharts.chart('container', {
      chart: {
        zoomType: 'xy',
        backgroundColor: 'transparent',
      },
      exporting: {
        enabled: false,
      },
      title: {
        text: 'TOP 12 Crypto Currency',
        align: 'left',
        style: {
          color: '#00C2BE',
        },
      },
      legend: {
        verticalAlign: 'top',
        align: 'left',
        layout: 'vertical',
        x: 120,
        y: 50,
        floating: true,
        itemStyle: {
          color: '#E5ECF4',
        },
      },
      xAxis: {
        allowDecimals: false,
        labels: {
          formatter: function () {
            return this.value;
          },
          style: {
            color: '#808AB8',
          },
        },
        categories: categories,
        tickPosition: 'inside',
        tickLength: 2,
      },
      yAxis: [
        {
          // Primary yAxis
          labels: {
            format: '{value} ',
            style: {
              color: '#00C2BE',
            },
          },
          title: {
            text: 'Price Changes',
            style: {
              color: '#00C2BE',
            },
          },
        },
        {
          // Secondary yAxis
          title: {
            text: 'Volume',
            style: {
              color: '#6B74A2',
            },
          },
          labels: {
            format: '{value} Billion',
            style: {
              color: '#6B74A2',
            },
          },
          opposite: true,
        },
      ],
      tooltip: {
        pointFormat: '{series.name} had stockpiled <b>{point.y:,.0f}</b>',
      },
      series: [
        {
          name: 'Volume',
          type: 'column',
          yAxis: 1,
          data: volumeData,
          color: '#6B74A2',
        },
        {
          name: 'Price Changes',
          type: 'spline',
          data: rangeData,
          color: '#00C2BE',
        },
      ],
    });
  }, [categories, volumeData, rangeData]);

  return (
    <div className='animate__animated animate__zoomInUp' id='container'>
      high chart
    </div>
  );
}
